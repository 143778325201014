
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Ban } from '@/ts/models/ban'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { DateFormatter } from '@/ts/formatters/dateFormatter'
  import { PersonState } from '@/ts/states/person/personState'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import BanForm from '@/components/forms/BanForm.vue'

  @Component({
    components: {
      BanForm,
    }
  })
  export default class Bans extends Vue {

    /* PROPERTIES */

    @Prop()
    public state!: PersonState


    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get bans() {
      return this.state.bans.bans
    }

    protected get canAdd() {
      return this.user.hasPermission(Claims.PROFILE_BANS, Crud.CREATE)
    }

    protected get canEdit() {
      return this.user.hasPermission(Claims.PROFILE_BANS, Crud.UPDATE)
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.status > ViewStatus.IN_PROGRESS && this.status < ViewStatus.FAILED
    }

    protected get queryParams() {
      return this.state.bans.queryParams
    }

    protected get status() {
      return this.state.bans.status
    }

    protected get user() {
      return this.authState.user
    }


  /** EVENTS */

    protected onClick(ban = new Ban()) {
      this.state.bans.reset()
      this.onRowClicked(ban)
    }

    protected onPageChanged(page: number) {
      this.state.bans.paginate(page)
    }

    protected onRowClicked(ban: Ban) {
      if (!ban.isExpired) {
        this.state.bans.setBan(ban)
        this.$bvModal.show('add-edit-ban')
      }
    }

    protected onSortChanged(context: ISortContext) {
      this.state.bans.sort(context)
    }


  /** PRIVATE METHODS */

    protected formatExpiryDate(ban: Ban) {
      if (ban.toDate == null && ban.expiryDate == null) {
        return 'Lifetime ban'
      }

      return DateFormatter.format(ban.expiryDate ?? ban.toDate)
    }

    protected isSelectable(ban: Ban) {
      return (ban != null && ban.isExpired) ? '' : 'cursor-pointer'
    }

  }
