
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Ban } from '@/ts/models/ban'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
  import { Media } from '@/ts/models/media'
  import { Note } from '@/ts/models/note'
  import { PersonState } from '@/ts/states/person/personState'
  import { PersonsState } from '@/ts/states/search/children/personsState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import BackToLink from '@/components/links/BackToLink.vue'
  import BanForm from '@/components/forms/BanForm.vue'
  import BanPanel from '@/components/panels/BanPanel.vue'
  import FingerPin from '@/components/badges/FingerPin.vue'
  import ImageView from '@/components/images/ImageView.vue'
  import NoteForm from '@/components/forms/NoteForm.vue'
  import StatusKey from '@/components/legends/StatusKey.vue'
  import TerminalCommentForm from '@/components/forms/TerminalCommentForm.vue'

  @Component({
    components: {
      BackToLink,
      BanForm,
      BanPanel,
      FingerPin,
      ImageView,
      NoteForm,
      StatusKey,
      TerminalCommentForm
    }
  })
  export default class PatronDetails extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: PersonState


    /** PRIVATE PROPERTIES */

    protected isEditingEmail = false
    protected isEditingMobile = false
    protected note = new Note()

    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get canAddNotes() {
      return this.user.hasPermission(Claims.PROFILE_NOTES, Crud.CREATE)
    }

    protected get canAddTerminalComments() {
      return this.user.hasPermission(Claims.PROFILE_TERMINALCOMMENTS, Crud.CREATE)
    }

    protected get canReadBans() {
      return this.user.hasPermission(Claims.PROFILE_BANS, Crud.READ)
    }

    protected get canReadDoB() {
      return this.user.hasPermission(Claims.PROFILE_DOB, Crud.READ)
    }

    protected get canReadEmail() {
      return this.user.hasPermission(Claims.PROFILE_EMAIL, Crud.READ)
    }

    protected get canReadName() {
      return this.user.hasPermission(Claims.PROFILE_NAME, Crud.READ)
    }

    protected get canReadNotes() {
      return this.user.hasPermission(Claims.PROFILE_NOTES, Crud.READ)
    }

    protected get canReadPhone() {
      return this.user.hasPermission(Claims.PROFILE_PHONE, Crud.READ)
    }

    protected get canReadTerminalComments() {
      return this.user.hasPermission(Claims.PROFILE_TERMINALCOMMENTS, Crud.READ)
    }

    protected get canUpdateBans() {
      return this.user.hasPermission(Claims.PROFILE_BANS, Crud.UPDATE)
    }

    protected get canUpdateEmail() {
      return this.user.hasPermission(Claims.PROFILE_EMAIL, Crud.UPDATE)
    }

    protected get canUpdatePhone() {
      return this.user.hasPermission(Claims.PROFILE_PHONE, Crud.UPDATE)
    }

    protected get dateOfBirth() {
      return DateFormatter.format(this.person.dob, DateStyle.Ordinal)
    }

    protected get isValidEmail() {
      return this.person.isValidEmail
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isVisible() {
      return this.status > ViewStatus.IN_PROGRESS
    }

    protected get latestPhotoDate() {
      return DateFormatter.format(this.person.latestPhoto.createdDate, DateStyle.Medium)
    }

    protected get lastNote() {
      return this.state.notes.last?.comment ?? ''
    }

    protected get patron() {
      return this.state.person.edit.patrons[0]
    }

    protected get person() {
      return this.state.person.edit
    }

    protected get status() {
      return this.state.status
    }

    protected get validationClass() {
      return (this.isValidEmail) ? 'text-secondary' : 'text-danger'
    }

    protected get user() {
      return this.authState.user
    }

    /** EVENTS */

    protected onEditBan(ban: Ban) {
      if (!ban.isExpired) {
        this.state.bans.setBan(ban)
        this.$bvModal.show('edit-ban')
      }
    }

    protected async onEditEmail(editing: boolean) {
      this.isEditingEmail = editing
      if (editing) {
        Vue.nextTick(() => {
          const email = this.$refs.email as HTMLInputElement
          email.focus()
        })
      }
      else {
        if (this.isValidEmail) {
          await this.state.patch()
        }
      }
    }

    protected async onEditMobile(editing: boolean) {
      this.isEditingMobile = editing
      if (editing) {
        Vue.nextTick(() => {
          const mobile = this.$refs.mobile as HTMLInputElement
          mobile.focus()
        })
      }
      else {
        await this.state.patch()
      }
    }

    protected onProofOfIdUpdated(media: Media) {
      this.person.proofOfId.photo = media
      const personsState = new PersonsState(this.$store)
      personsState.update(this.person)
    }
  }

