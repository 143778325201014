
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { Crud } from '@/ts/enums/crud'
  import { EventLog } from '@/ts/models/eventLog'
  import { EventLogCode } from '@/ts/models/eventLogCode'
  import { EventLogCodeType } from '@/ts/enums/eventLogCodeType'
  import { PersonState } from '@/ts/states/person/personState'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  import EventCodes from '@/components/eventcodes/EventCodes.vue'
  import EventLegend from '@/components/legends/EventLegend.vue'

  @Component({
    components: {
      EventCodes,
      EventLegend
    }
  })
  export default class Events extends Vue {

    

    /* PROPERTIES */

    @Prop({ default: Crud.NONE })
    public eventMode!: Crud

    @Prop()
    public state!: PersonState


    /** PRIVATE PROPERTIES */

    protected selectedIds = this.state.events.selected.ids


    /** OBSERVERS */

    @Watch('state.events.selected.ids')
    protected onStateSelectedIdsChanged(ids: number[]) {
      this.selectedIds = ids
    }

    @Watch('selectedIds', { immediate: false, deep: true })
    protected onSelectedIdsChanged(newIds: number[], oldIds: number[]) {
      if (oldIds == undefined) {
        this.state.events.selected.select(newIds[0])
      }
      else if (newIds.length > oldIds.length) {
        const id = newIds.filter(i => !oldIds.includes(i))[0]
        this.state.events.selected.select(id)
      }
      else if (newIds.length < oldIds.length) {
        const id = oldIds.filter(i => !newIds.includes(i))[0]
        this.state.events.selected.deselect(id)
      }
    }


    /** COMPUTED PROPERTIES */

    protected get canSelect() {
      return this.eventMode == Crud.UPDATE
    }

    protected get eventLogs() {
      return this.state.events.events
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.status > ViewStatus.IN_PROGRESS && this.status < ViewStatus.FAILED
    }

    protected get queryParams() {
      return this.state.events.queryParams
    }

    protected get showOrganisation() {
      return this.state.showOrganisation
    }

    protected get status() {
      return this.state.events.status
    }


    /** EVENTS */

    protected onPageChanged(page: number) {
      this.state.events.paginate(page)
    }

    protected onSortChanged(context: ISortContext) {
      this.state.events.sort(context)
    }

    /** PRIVATE METHODS */

    protected enterClass(codes: EventLogCode[]) {
      if (codes.find(c => c.id == EventLogCodeType.REENTERED) != null) {
        return 'text-warning'
      }

      if (codes.find(c => c.id == EventLogCodeType.DID_NOT_ENTER) != null) {
        return 'text-light-gray'
      }

      if (codes.find(c => c.id == EventLogCodeType.DENIED_ENTRY) != null) {
        return 'text-danger'
      }

      return 'text-success'
    }

    protected fingerpinClass(codes: EventLogCode[]) {
      if (codes.find(c => c.id == EventLogCodeType.FINGERPIN_MATCH) != null) {
        return 'text-success'
      }

      if (codes.find(c => c.id == EventLogCodeType.FINGERPIN_FALSE_MATCH) != null) {
        return 'text-danger'
      }

      if (codes.find(c => c.id == EventLogCodeType.FINGERPIN_NO_MATCH) != null) {
        return 'text-danger'
      }

      return 'text-light-gray'
    }

    protected poidClass(codes: EventLogCode[]) {
      if (codes.find(c => c.id == EventLogCodeType.STATUS_IDQUERY) != null) {
        return 'text-info'
      }

      if (codes.find(c => c.id == EventLogCodeType.PROOF_OF_ID_MATCH) != null) {
        return 'text-success'
      }

      if (codes.find(c => c.id == EventLogCodeType.PROOF_OF_ID_FALSE_MATCH) != null) {
        return 'text-danger'
      }

      if (codes.find(c => c.id == EventLogCodeType.PROOF_OF_ID_FALSE_MATCH) != null) {
        return 'text-danger'
      }

      return 'text-light-gray'
    }

    protected isRowSelected(event: EventLog) {
      return (event != null && this.state.events.selected.isSelected(event.id)) ? 'bg-selected cursor-pointer' : 'cursor-pointer'
    }
  }
