
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { Note } from '@/ts/models/note'
  import { PersonState } from '@/ts/states/person/personState'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import NoteForm from '@/components/forms/NoteForm.vue'

  @Component({
    components: {
      NoteForm,
    }
  })
  export default class Notes extends Vue {

    /** PROPERTIES */

    @Prop()
    public state!: PersonState


    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get canAdd() {
      return this.user.hasPermission(Claims.PROFILE_NOTES, Crud.CREATE)
    }

    protected get canEdit() {
      return this.user.hasPermission(Claims.PROFILE_NOTES, Crud.UPDATE)
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isNone() {
      return this.status == ViewStatus.NONE
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return (this.status > ViewStatus.IN_PROGRESS && this.status < ViewStatus.FAILED) || (this.status == ViewStatus.NONE)
    }

    protected get notes() {
      return this.state.notes.notes
    }

    protected get queryParams() {
      return this.state.notes.queryParams
    }

    protected get status() {
      return this.state.notes.status
    }

    protected get user() {
      return this.authState.user
    }


    /** EVENTS */

    protected onClick(note = new Note()) {
      this.state.notes.reset()
      this.onRowClicked(note)
    }

    protected onPageChanged(page: number) {
      this.state.notes.paginate(page)
    }

    protected onRowClicked(note: Note) {
      this.state.notes.setNote(note)
      this.$bvModal.show('add-edit-note')
    }

    protected onSortChanged(context: ISortContext) {
      this.state.notes.sort(context)
    }
  }
