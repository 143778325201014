
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import StatusKey from './StatusKey.vue'

  @Component({
    components: {
      StatusKey
    }
  })
  export default class StatusLegend extends Vue {

  /** PROPERTIES */

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus


  /** COMPUTED PROPERTIES */

    protected get isVisible() {
      return this.status > ViewStatus.NONE && this.status <= ViewStatus.SUCCEEDED
    }
  }
