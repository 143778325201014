
  import { Component, Vue, Prop } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { PersonState } from '@/ts/states/person/personState'
  import { PersonStatusType } from '@/ts/enums/personStatusType'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { TerminalComment } from '@/ts/models/terminalComment'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import StatusKey from '@/components/legends/StatusKey.vue'
  import TerminalCommentForm from '@/components/forms/TerminalCommentForm.vue'

  @Component({
    components: {
      StatusKey,
      TerminalCommentForm,
    }
  })
  export default class TerminalComments extends Vue {

    /* PROPERTIES */

    @Prop()
    public state!: PersonState


    /** PRIVATE PROPERTIES */

    protected idQueryStatus = PersonStatusType.IDQUERY
    private authState = new AuthState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get canAdd() {
      return this.user.hasPermission(Claims.PROFILE_TERMINALCOMMENTS, Crud.CREATE)
    }

    protected get canEdit() {
      return this.user.hasPermission(Claims.PROFILE_TERMINALCOMMENTS, Crud.UPDATE)
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.status > ViewStatus.IN_PROGRESS && this.status < ViewStatus.FAILED
    }

    protected get queryParams() {
      return this.state.terminalComments.queryParams
    }

    protected get status() {
      return this.state.terminalComments.status
    }

    protected get terminalComments() {
      return this.state.terminalComments.terminalComments
    }

    protected get user() {
      return this.authState.user
    }

    /** EVENTS */

    protected onClick(terminalComment = new TerminalComment()) {
      this.state.terminalComments.reset()
      this.onRowClicked(terminalComment)
    }

    protected onPageChanged(page: number) {
      this.state.terminalComments.paginate(page)
    }

    protected onRowClicked(terminalComment: TerminalComment) {
      this.state.terminalComments.setTerminalComment(terminalComment)
      this.$bvModal.show('add-edit-terminal-comment')
    }

    protected onSortChanged(context: ISortContext) {
      this.state.terminalComments.sort(context)
    }


    /** PRIVATE PROPERTIES */

    protected isSelectable(terminalComment: TerminalComment) {
      return (terminalComment != null && !terminalComment.isExpired) ? 'cursor-pointer' : ''
    }
  }
