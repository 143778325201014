
  import { Component, Vue, Watch } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { Person } from '@/ts/models/person'
  import { PersonState } from '@/ts/states/person/personState'
  import { Route } from '@/ts/models/route'
  import { Routes } from '@/ts/constants/routes'
  import { SearchState } from '@/ts/states/search/searchState'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import HeadPanel from '@/components/panels/HeadPanel.vue'
  import PatronDetails from './components/PatronDetails.vue'
  import PersonFooter from '@/components/person/PersonFooter.vue'
  import PersonNavigation from '@/components/navigation/PersonNavigation.vue'
  import ProgressArrows from '@/components/progress/ProgressArrows.vue'

  @Component({
    components: {
      HeadPanel,
      PatronDetails,
      PersonFooter,
      PersonNavigation,
      ProgressArrows
    }
  })
  export default class PatronProfile extends Vue {

    /** ROUTE PARAMS */

    public patronId = 0


    /** PRIVATE PROPERTIES */

    protected mode = Crud.UPDATE
    protected returnRoute?: Route

    private authState = new AuthState(this.$store)
    private state = new PersonState(this.$store)
    private searchState = new SearchState(this.$store)


    /** LIFECYCLE  */


    public created() {
      this.state.showOrganisation = false
      this.state.getMetadata()
      this.returnRoute = this.state.returnRoute
    }


    /** OBSERVER */

    @Watch('state.person.original', { immediate: true })
    protected onPatronChanged(person: Person) {
      this.state.log(person, person.patrons[0])
    }


    /** COMPUTED PROPERTIES */

    protected get canSubmitPoliceReports() {
      return this.authState.user.hasPermission(Claims.PATRON_POLICEREPORTS, Crud.CREATE)
    }

    protected get index() {
      return this.searchState.index
    }

    protected get isSelected() {
      return this.searchState.persons.selected.isSelected(this.state.person.original)
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get showPanel() {
      return (this.searchState.persons.selected.hasMultipleSelection && this.isSelected) || this.state.events.selected.ids.length > 0
    }

    protected get status() {
      return this.state.status
    }

    protected get total() {
      return this.searchState.total
    }


    /** EVENTS */

    protected onClick(person: Person) {
      if (!this.isCurrentPatron(person.patrons[0].id)) {
        this.state = new PersonState(this.$store, person)
        this.state.getMetadata()

        this.$router.push({ name: Routes.PATRON_PROFILE, params: { patronId: person.patrons[0].id.toString() } })
      }
    }

    protected onMove(index: number) {
      const person = this.searchState.moveToPerson(index);
      this.onClick(person)
    }

    protected onReset() {
      this.state.clear()
    }


    /** PRIVATE PROPERTIES */

    private isCurrentPatron(id: number) {
      return this.patronId == id
    }
  }

