
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { PersonState } from '@/ts/states/person/personState'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { ViewStatus } from '@/ts/enums/viewStatus'



  @Component
  export default class Communications extends Vue {

    /* PROPERTIES */

    @Prop()
    public state!: PersonState


    /** COMPUTED PROPERTIES */

    protected get communications() {
      return this.state.communications.communications
    }

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.status > ViewStatus.IN_PROGRESS && this.status < ViewStatus.FAILED
    }

    protected get queryParams() {
      return this.state.communications.queryParams
    }

    protected get status() {
      return this.state.communications.status
    }


    /** EVENTS */


    protected onPageChanged(page: number) {
      this.state.communications.paginate(page)
    }
   
    protected onSortChanged(context: ISortContext) {
      this.state.communications.sort(context)
    }
  }
