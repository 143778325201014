
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { PersonState } from '@/ts/states/person/personState'
  import { ISortContext } from '@/ts/interfaces/sortContext'
  import { ViewEventType } from '@/ts/enums/viewEventType'
  import { ViewStatus } from '@/ts/enums/viewStatus'



  @Component
  export default class ViewEvents extends Vue {

    /* PROPERTIES */

    @Prop()
    public state!: PersonState


    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    protected get isSorting() {
      return this.status == ViewStatus.SORTING
    }

    protected get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    protected get isUpdating() {
      return this.status == ViewStatus.UPDATING
    }

    protected get isVisible() {
      return this.status > ViewStatus.IN_PROGRESS && this.status < ViewStatus.FAILED
    }

    protected get queryParams() {
      return this.state.viewEvents.queryParams
    }

    protected get status() {
      return this.state.viewEvents.status
    }

    protected get viewEvents() {
      return this.state.viewEvents.viewEvents
    }


    /** EVENTS */

    protected onPageChanged(page: number) {
      this.state.viewEvents.paginate(page)
    }

    protected onSortChanged(context: ISortContext) {
      this.state.viewEvents.sort(context)
    }


    /** METHODS */


    protected viewEventType(type: ViewEventType) {
      switch (type) {
        case ViewEventType.VIEW_BAN:
          return 'Ban viewed'
        case ViewEventType.VIEW_USER:
          return 'Profile viewed'
        default:
          return 'Searched'
      }
    }
  }
